import React from "react";

function TableRow({ data }: { data: string }) {
  return (
    <td className="px-2 py-4 max-w-xs">
      <div className="text-sm text-gray-900 break-words">{data}</div>
    </td>
  );
}

export default TableRow;
