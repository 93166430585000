export const REQUEST_ERROR = "REQUEST_ERROR";
export const REQUEST_CLEAR_ERROR = "REQUEST_CLEAR_ERROR";
export const REQUEST_MESSAGE = "REQUEST_MESSAGE";
export const REQUEST_CLEAR_MESSAGE = "REQUEST_CLEAR_MESSAGE";

export interface SetErrorAction {
  type: typeof REQUEST_ERROR;
  message: string;
}

export interface ClearErrorAction {
  type: typeof REQUEST_CLEAR_ERROR;
}

export interface SetMessageAction {
  type: typeof REQUEST_MESSAGE;
  message: string;
}

export interface ClearMessageAction {
  type: typeof REQUEST_CLEAR_MESSAGE;
}

export type MessageActionTypes =
  | SetErrorAction
  | ClearErrorAction
  | ClearMessageAction
  | SetMessageAction;
