import { UserInterface } from "../../Types/Interfaces";
import { AppActions } from "../Store";
import { UserType } from "./UserTypes";

export function SaveUserToStorage(data: UserInterface): AppActions {
  localStorage.setItem("user_email", data.email);
  localStorage.setItem("user_password", data.password);
  return { type: UserType.SaveUser };
}

export function LoadUserFromStorage(): AppActions {
  const userEmail = localStorage.getItem("user_email");
  let userData: UserInterface = {
    email: "",
    password: "",
  };

  if (userEmail == null) {
    return {
      type: UserType.StorageError,
    };
  }
  userData.email = userEmail;

  const userPassword = localStorage.getItem("user_password");

  if (userPassword !== null) {
    userData.password = userPassword;
  }

  return {
    type: UserType.LoadUser,
    payload: userData,
  };
}
