import { userInitialState } from "..";
import { UserState } from "../../Types/Interfaces";
import { AppActions } from "../Store";
import { UserType } from "./UserTypes";

function userReducer(state = userInitialState, action: AppActions): UserState {
  switch (action.type) {
    case UserType.LoadUser:
      return {
        ...state,
        user: action.payload,
        hasUser: true,
      };

    case UserType.StorageError:
      return {
        ...state,
        hasUser: false,
      };

    default:
      return state;
  }
}

export default userReducer;
