import React from "react";

interface Props {
  headers: any[];
  children?: React.ReactNode;
}

const Table: React.FC<Props> = ({ headers, children }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-600 text-center">
        <tr>
          {headers.map((header) => {
            return (
              <th
                key={header.name}
                scope="col"
                className="px-3 py-3 text-xs font-medium text-white
                                      uppercase tracking-wider"
              >
                {header.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-center">
        {children}
      </tbody>
    </table>
  );
};

export default Table;
