import { UserState } from "../Types/Interfaces";

export { loginToAPI } from "./Auth/AuthAction";

export {
  fetchAccountsFromAPI,
  addAccountToApi,
  postTransactionToApi,
  fetchTransactionsFromAPI,
  updateTransactionDateToApi,
  updateTransactionAmountToApi,
  deleteTransaction,
} from "./AMS/AMSActions";

export {
  authClearError,
  successMessage,
  clearMessage,
} from "./Messages/MessageActions";

export { LoadUserFromStorage } from "./User/UserAction";

export const userInitialState: UserState = {
  user: {
    email: "",
    password: "",
  },
  hasUser: false,
};
