import { Link } from "react-router-dom";
import NavBarLogo from "../Layout/NavBarLogo";

const NavBar = () => {
  return (
    <header className="bg-primary_dark">
      <nav className="container mx-auto flex justify-between content-center py-3">
        <NavBarLogo />
        <div>
          <ul className="flex">
            <li className="p-2">
              <Link className="text-white" to="/dashboard">
                DashBoard
              </Link>
            </li>
            <li className="p-2">
              <Link className="text-white" to="/add-transaction">
                Add Transaction
              </Link>
            </li>
            <li className="p-2">
              <Link className="text-white" to="/add-account">
                Add Account
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
