import React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Store";

interface Props {
  isError: boolean;
  message: string | null;
}

const AlertBanner: React.FC<Props> = ({ isError, message }) => {
  return (
    <div
      className={`text-${
        isError ? "red" : "green"
      } px-6 py-4 border-0 mb-4 bg-${
        isError ? "red" : "green"
      }-100 max-w-xl mx-auto border-l-4 border-${
        isError ? "red" : "green"
      }-500 is-visible`}
      id="alert"
    >
      <span
        className={`inline-block w-full mr-8 text-${
          isError ? "red" : "green"
        }-700`}
      >
        <b className="capitalize">{isError ? "Error!" : "Success!"}</b>{" "}
        {message}
      </span>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isError: state.messageState.hasError,
    message: state.messageState.requestMessage,
  };
};

export default connect(mapStateToProps)(AlertBanner);
