import { MessageState } from "../../Types/Interfaces";
import { AppActions } from "../Store";
import {
  REQUEST_ERROR,
  REQUEST_CLEAR_ERROR,
  REQUEST_MESSAGE,
  REQUEST_CLEAR_MESSAGE,
} from "./MessageTypes";

const initialState: MessageState = {
  hasMessage: false,
  hasError: false,
  requestMessage: null,
};

const messageReducer = (
  state = initialState,
  action: AppActions
): MessageState => {
  switch (action.type) {
    case REQUEST_ERROR:
      return {
        ...state,
        hasError: true,
        requestMessage: action.message,
      };
    case REQUEST_CLEAR_ERROR:
      return {
        ...state,
        hasError: false,
        requestMessage: null,
      };
    case REQUEST_MESSAGE:
      return {
        ...state,
        hasMessage: true,
        requestMessage: action.message,
      };
    case REQUEST_CLEAR_MESSAGE:
      return {
        ...state,
        hasMessage: false,
        requestMessage: null,
      };
    default:
      return state;
  }
};

export default messageReducer;
