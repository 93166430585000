import React from "react";
import { TransactionRequest } from "../Types/Interfaces";
import DeleteIcon from "./DeleteIcon";
import EditIcon from "./EditIcon";

interface Props {
  transaction: TransactionRequest;
  updateTransactionAmount: (amount: number, id: string) => void;
  updateTransactionDate: (date: number, id: string) => void;
  deleteTransaction: (data: TransactionRequest) => void;
  bgColor: string;
}

function TransactionDetail({
  transaction,
  updateTransactionAmount,
  updateTransactionDate,
  deleteTransaction,
  bgColor,
}: Props) {
  var transactionDate = new Date(transaction.date * 1000);
  var displayDate =
    transactionDate.toLocaleString(undefined, { day: "2-digit" }) +
    "-" +
    transactionDate.toLocaleDateString(undefined, { month: "short" }) +
    "-" +
    transactionDate.toLocaleDateString(undefined, { year: "numeric" });

  const onAmountClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    updateTransactionAmount(transaction.amount, transaction.id);
  };

  const onDateClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    updateTransactionDate(transaction.date, transaction.id);
  };

  const onDeleteClickHandler = () => {
    deleteTransaction(transaction);
  };

  const onEditClickHandler = () => {
    console.log("edit clicked...");
  };

  return (
    <tr className={`${bgColor === "dark" ? "bg-gray-300" : "bg-gray-100"}`}>
      <td className="px-3 py-4">
        <div className="text-sm text-gray-900" onClick={onDateClickHandler}>
          {displayDate}
        </div>
      </td>
      <td className="px-3 py-4">
        <span className="px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {transaction.debitAccount}
        </span>
      </td>
      <td className="px-3 py-4">
        <span className="px-2 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          {transaction.creditAccount}
        </span>
      </td>
      <td className="px-3 py-4">
        <div className="text-gray-900" onClick={onAmountClickHandler}>
          <span>&#8377;</span> {transaction.amount} INR
        </div>
      </td>
      <td className="px-3 py-4">
        <button className="h-7 w-7" onClick={onEditClickHandler}>
          <EditIcon />
        </button>
      </td>
      <td className="px-3 py-4">
        <button className="h-7 w-7" onClick={onDeleteClickHandler}>
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
}

export default TransactionDetail;
