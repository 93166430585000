import React from "react";

interface Props {
  heading: string
}

const FormHeading: React.FC<Props> = ({ heading }) => {
  return (
    <div className="px-4 sm:px-0 mb-10">
      <h3 className="text-lg font-medium leading-6 text-gray-900 text-center">
        {heading}
      </h3>
    </div>
  );
}

export default FormHeading;
