import { AmsState } from "../../Types/Interfaces";
import { AppActions } from "../Store";
import * as ams from "./AMSTypes";

const initialState: AmsState = {
  accounts: [],
  transactions: [],
  isLoading: false,
};

const amsReducer = (state = initialState, action: AppActions): AmsState => {
  switch (action.type) {
    case ams.REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case ams.ACCOUNTS_LOADED:
      return {
        ...state,
        accounts: action.accounts,
        isLoading: false,
      };
    case ams.PERSIST_ACCOUNT:
      return {
        ...state,
        accounts: [...state.accounts, action.account],
        isLoading: false,
      };
    case ams.TRANSACTIONS_LOADED:
      return {
        ...state,
        isLoading: false,
        transactions: action.transactions,
      };
    case ams.PERSIST_TRANSACTION:
      return {
        ...state,
        isLoading: false,
        transactions: [...state.transactions, action.transaction],
      };
    case ams.UPDATE_TRANSACTION_DATE:
      return {
        ...state,
        isLoading: false,
        transactions: state.transactions.map((transaction) =>
          transaction.id === action.payload.id
            ? { ...transaction, date: action.payload.date }
            : transaction
        ),
      };
    case ams.UPDATE_TRANSACTION_AMOUNT:
      return {
        ...state,
        isLoading: false,
        transactions: state.transactions.map((transaction) =>
          transaction.id === action.payload.id
            ? { ...transaction, amount: action.payload.amount }
            : transaction
        ),
      };
    case ams.DELETE_TRANSACTION:
      return {
        ...state,
        isLoading: false,
        transactions: state.transactions.filter(
          (transaction) => transaction.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
};

export default amsReducer;
