import { AppActions } from "../Store";
import {
  REQUEST_CLEAR_ERROR,
  REQUEST_MESSAGE,
  REQUEST_CLEAR_MESSAGE,
} from "./MessageTypes";
export const authClearError = (): AppActions => {
  return {
    type: REQUEST_CLEAR_ERROR,
  };
};

export const successMessage = (message: string): AppActions => {
  return {
    type: REQUEST_MESSAGE,
    message,
  };
};

export const clearMessage = (): AppActions => {
  return {
    type: REQUEST_CLEAR_MESSAGE,
  };
};
