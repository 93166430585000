import { connect } from "react-redux";
import { AppState } from "../Redux/Store";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
  isAuthenticated,
  component: Component,
  ...props
}: any) => {
  return (
    <Route
      {...props}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.authState.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
