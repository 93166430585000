import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "./Redux/Store";
import Dashboard from "./Components/DashBoard";
import AddTransaction from "./Components/AddTransaction";
import Login from "./Components/Login";
import AddAccount from "./Components/AddAccount";
import ProtectedRoute from "./Components/ProtectedRoute";

function App() {
  document.body.style.background = "#EBF7E3";
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute
            exact
            path="/add-transaction"
            component={AddTransaction}
          />
          <ProtectedRoute exact path="/add-account" component={AddAccount} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
