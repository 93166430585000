interface PropTypes {
  searchText: string;
  searchHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
function SearchComponent(props: PropTypes) {
  return (
    <div className="mt-10 flex justify-around mb-2 items-center">
      <p>{props.searchText}</p>
      <input
        type="text"
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
        placeholder="Search..."
        onChange={props.searchHandler}
      />
    </div>
  );
}

export default SearchComponent;
