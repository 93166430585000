import * as ams from "./AMSTypes";
import { REQUEST_ERROR } from "../Messages/MessageTypes";
import AuthException from "../../exceptions/AuthException";
import { AUTH_ERROR } from "../Auth/AuthTypes";
import { Transaction, TransactionRequest } from "../../Types/Interfaces";
import { Dispatch } from "redux";
import { AppActions, AppState } from "../Store";
import { successMessage } from "../Messages/MessageActions";

const apiURL = process.env.REACT_APP_API_URL;
//const apiURL = "http://192.168.1.101:8083";

export const fetchAccountsFromAPI = () => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });

    const state = getState();

    try {
      const data = await fetchData("accounts", state.authState.token);
      dispatch({
        type: ams.ACCOUNTS_LOADED,
        accounts: data,
      });
    } catch (e) {
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const addAccountToApi = (accountData: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();
    let jsonData = JSON.stringify({
      name: accountData,
    });
    try {
      const data = await postData("accounts", state.authState.token, jsonData);
      dispatch({
        type: ams.PERSIST_ACCOUNT,
        account: data,
      });
      dispatch(successMessage(`Account saved`));
    } catch (e) {
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const fetchTransactionsFromAPI = () => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();
    try {
      const data = await fetchData("transactions", state.authState.token);
      dispatch({
        type: ams.TRANSACTIONS_LOADED,
        transactions: data,
      });
    } catch (e) {
      if (e instanceof AuthException) {
        dispatch({
          type: AUTH_ERROR,
        });
        return;
      }
      dispatch({
        type: AUTH_ERROR,
      });
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const postTransactionToApi = (transactionObject: Transaction) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();

    let requestBody = JSON.stringify(transactionObject);
    try {
      const data = await postData(
        "transactions",
        state.authState.token,
        requestBody
      );
      dispatch({
        type: ams.PERSIST_TRANSACTION,
        transaction: data,
      });
      dispatch(successMessage(`Transaction saved`));
    } catch (e) {
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const updateTransactionDateToApi = (
  transactionDate: number,
  transactionID: string
) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();
    let updatedPaylod = {
      date: transactionDate,
    };

    let requestBody = JSON.stringify(updatedPaylod);
    try {
      const res = await fetch(`${apiURL}/transactions/${transactionID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.authState.token,
        },
        body: requestBody,
      });
      if (!res.ok) {
        let errRes = await res.json();
        let message = errRes.error;
        throw Error(message);
      }
      dispatch({
        type: ams.UPDATE_TRANSACTION_DATE,
        payload: {
          id: transactionID,
          date: transactionDate,
        },
      });
      dispatch(
        successMessage(`Transaction with id '${transactionID}' updated`)
      );
    } catch (e) {
      console.log(e);
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const updateTransactionAmountToApi = (
  transactionAmount: number,
  transactionID: string
) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();
    let updatedPaylod = {
      amount: transactionAmount,
    };

    let requestBody = JSON.stringify(updatedPaylod);
    try {
      const res = await fetch(`${apiURL}/transactions/${transactionID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.authState.token,
        },
        body: requestBody,
      });
      if (!res.ok) {
        let errRes = await res.json();
        let message = errRes.error;
        throw Error(message);
      }
      dispatch({
        type: ams.UPDATE_TRANSACTION_AMOUNT,
        payload: {
          id: transactionID,
          amount: transactionAmount,
        },
      });
      dispatch(
        successMessage(`Transaction with id '${transactionID}' updated`)
      );
    } catch (e) {
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

export const deleteTransaction = (transaction: TransactionRequest) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch({
      type: ams.REQUEST_LOADING,
    });
    const state = getState();

    try {
      const res = await fetch(`${apiURL}/transactions/${transaction.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.authState.token,
        },
      });
      if (!res.ok) {
        let errRes = await res.json();
        let message = errRes.error;
        throw Error(message);
      }
      dispatch({
        type: ams.DELETE_TRANSACTION,
        payload: transaction,
      });
      dispatch(
        successMessage(`Transaction with id '${transaction.id}' deleted`)
      );
    } catch (e) {
      dispatch({
        type: REQUEST_ERROR,
        message: e.message,
      });
    }
  };
};

async function fetchData(endpoint: string, token: string) {
  const res = await fetch(`${apiURL}/${endpoint}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (!res.ok) {
    let errRes = await res.json();
    let message = errRes.error;
    if (res.status === 401) {
      throw new AuthException(message);
    }
    throw Error(message);
  }
  const data = await res.json();

  return data;
}

async function postData(endpoint: string, token: string, jsonData: string) {
  const res = await fetch(`${apiURL}/${endpoint}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: jsonData,
  });
  if (!res.ok) {
    let errRes = await res.json();
    let message = errRes.error;
    throw Error(message);
  }
  return await res.json();
}
