import TableRow from "../Layout/TableRow";
import { Account } from "../Types/Interfaces";
import EditIcon from "./EditIcon";

type AccountProps = {
  account: Account;
  bgColor: string;
};

function AccountDetail({ account, bgColor }: AccountProps) {
  return (
    <tr className={`${bgColor === "dark" ? "bg-gray-300" : "bg-gray-100"}`}>
      <TableRow data={account.name} />
      <td className="px-3 py-4">
        <div className="text-gray-900">
          <span
            className={`px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-${
              account.balance < 0 ? "red" : "green"
            }-100 text-${account.balance < 0 ? "red" : "green"}-800`}
          >
            &#8377; {account.balance} INR
          </span>
        </div>
      </td>
      <td className="px-3 py-4">
        <button className="text-sm font-medium h-7 w-7">
          <EditIcon />
        </button>
      </td>
    </tr>
  );
}

export default AccountDetail;
