import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loginToAPI,
  authClearError,
  userInitialState,
  LoadUserFromStorage,
} from "../Redux";
import AlertBanner from "../Components/AlertBanner";
import { useHistory } from "react-router-dom";
import Spinner from "../Layout/Spinner/Spinner";
import { AppActions, AppState } from "../Redux/Store";
import { ThunkDispatch } from "redux-thunk";
import { SaveUserToStorage } from "../Redux/User/UserAction";
import { UserInterface } from "../Types/Interfaces";

interface Props {
  hasError: boolean;
  clearError: () => void;
  isAuthenticated: boolean;
  loginAPI: (email: string, password: string) => void;
  isLoading: boolean;
  user: typeof userInitialState;
  loadUser: () => void;
  saveUser: (user: UserInterface) => void;
}

function Login({
  hasError,
  clearError,
  isAuthenticated,
  loginAPI,
  isLoading,
  user,
  loadUser,
  saveUser,
}: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const apiKeyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => loadUser(), [loadUser]);

  useEffect(() => {
    if (user.hasUser) {
      loginAPI(user.user.email, user.user.password);
    }
    // eslint-disable-next-line
  }, [user.hasUser]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (hasError) {
    setTimeout(() => {
      clearError();
    }, 3000);
  }

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let userData: UserInterface = {
      email,
      password,
    };
    saveUser(userData);
    loginAPI(email, password);
    //history.push("/dashboard");
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-accent py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        {hasError ? <AlertBanner /> : ""}
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={formSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                type="email"
                autoComplete="off"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300
                               placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-secondary
                               focus:border-secondary focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={emailChangeHandler}
              />
            </div>
            <div>
              <label htmlFor="api-key" className="sr-only">
                Api Key
              </label>
              <input
                id="api-key"
                type="password"
                autoComplete="off"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300
                               placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-secondary
                               focus:border-secondary focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={apiKeyChangeHandler}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm
                            font-medium rounded-md text-white bg-primary_dark hover:bg-primary_dark_dark focus:outline-none
                            focus:ring-2 focus:ring-offset-2 focus:ring-secondary_dark"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-white group-hover:text-white-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0
                  012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: state.authState.isLoading,
    isAuthenticated: state.authState.isAuthenticated,
    hasError: state.messageState.hasError,
    requestMessage: state.messageState.requestMessage,
    user: state.userState,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    loginAPI: (email: string, password: string) =>
      dispatch(loginToAPI(email, password)),
    clearError: () => dispatch(authClearError()),
    loadUser: () => dispatch(LoadUserFromStorage()),
    saveUser: (data: UserInterface) => dispatch(SaveUserToStorage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
