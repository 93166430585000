import { UserInterface } from "../../Types/Interfaces";

export enum UserType {
  LoadUser = "LOAD_USER",
  SaveUser = "SAVE_USER",
  StorageError = "STORAGE_ERROR",
}

export interface SaveUserType {
  type: UserType.SaveUser;
}

export interface LoadUserType {
  type: UserType.LoadUser;
  payload: UserInterface;
}

export interface StorageErrorType {
  type: UserType.StorageError;
}

export type UserActionTypes = SaveUserType | LoadUserType | StorageErrorType;
