import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "./Auth/AuthReducer";
import amsReducer from "./AMS/AMSReducer";
import messageReducer from "./Messages/MessageReducer";
import { AccountActionTypes, TransactionActionTypes } from "./AMS/AMSTypes";
import { MessageActionTypes } from "./Messages/MessageTypes";
import { AuthActionTypes } from "./Auth/AuthTypes";
import { UserActionTypes } from "./User/UserTypes";
import userReducer from "./User/UserReducer";

const rootReducer = combineReducers({
  authState: authReducer,
  amsState: amsReducer,
  messageState: messageReducer,
  userState: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export type AppActions =
  | TransactionActionTypes
  | AccountActionTypes
  | MessageActionTypes
  | AuthActionTypes
  | UserActionTypes;

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
  )
);

export default store;
