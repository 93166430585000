import { AUTH_LOADING, LOGIN_SUCCESS, AUTH_ERROR } from "./AuthTypes";

import { REQUEST_ERROR } from "../Messages/MessageTypes";
import { Dispatch } from "redux";
import { AppActions } from "../Store";

const apiURL = process.env.REACT_APP_API_URL;
//const apiURL = "http://192.168.1.101:8083";

export const loginToAPI =
  (email: string, password: string) =>
  async (dispatch: Dispatch<AppActions>) => {
    dispatch(setLoading());

    //let user = state.authState.user;
    try {
      const res = await fetch(`${apiURL}/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (!res.ok) {
        let errRes = await res.json();
        let message = errRes.error;
        throw Error(message);
      }
      const data = await res.json();
      dispatch({
        type: LOGIN_SUCCESS,
        token: data.token,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
      dispatch({
        type: REQUEST_ERROR,
        message: err.message,
      });
    }
  };

export const setLoading = (): AppActions => {
  return {
    type: AUTH_LOADING,
  };
};
