import { Account, TransactionRequest } from "../Types/Interfaces";

export function parseTransactions(
  transactions: TransactionRequest[],
  accounts: Account[]
): Array<TransactionRequest> {
  let updatedTransactions: TransactionRequest[] = [];
  updatedTransactions = transactions.map((transaction) => {
    let transactionDTO: TransactionRequest = {
      id: "",
      amount: 0,
      description: "",
      date: 0,
      type: "",
      debitAccount: "",
      creditAccount: "",
    };
    transactionDTO.id = transaction.id;
    transactionDTO.amount = transaction.amount;
    transactionDTO.date = transaction.date;
    transactionDTO.type = transaction.description;
    transactionDTO.description = transaction.description;
    accounts.forEach((account) => {
      if (transaction.debitAccount === account.id) {
        transactionDTO.debitAccount = account.name;
      }
      if (transaction.creditAccount === account.id) {
        transactionDTO.creditAccount = account.name;
      }
    });
    return transactionDTO;
  });
  return updatedTransactions;
}

export function listBackgroundColor(index: number): string {
  return index % 2 ? "dark" : "light";
}
