import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../Layout/Table";
import AccountDetail from "./AccountDetail";
import { Account } from "../Types/Interfaces";
import { AppState } from "../Redux/Store";
import SearchComponent from "./SearchComponent";
import { listBackgroundColor } from "../Utils/HelperFunctions";

type AccountsProps = {
  accounts: Account[];
};

function AccountsList({ accounts }: AccountsProps) {
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
  const [filterText, setFIlterText] = useState("");

  useEffect(() => {
    setFilteredAccounts(
      accounts.filter((account) =>
        account.name.toLowerCase().includes(filterText)
      )
    );
  }, [accounts, filterText]);

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFIlterText(e.target.value.toLowerCase());
  };

  const headers = [
    {
      name: "Account Name",
    },
    {
      name: "Account Balance",
    },
    {
      name: "Edit",
    },
  ];
  return (
    <>
      <SearchComponent searchText="Accounts" searchHandler={searchHandler} />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table headers={headers}>
                {filteredAccounts
                  .slice(0, 10)
                  .map((account: Account, index) => {
                    const bgColor = listBackgroundColor(index);
                    return (
                      <AccountDetail
                        key={account.id}
                        account={account}
                        bgColor={bgColor}
                      />
                    );
                  })}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    accounts: state.amsState.accounts,
  };
};

export default connect(mapStateToProps)(AccountsList);
