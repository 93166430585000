import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NavBar from "./NavBar";
import TransactionList from "./TransactionList";
import {
  fetchAccountsFromAPI,
  fetchTransactionsFromAPI,
  updateTransactionDateToApi,
  clearMessage,
  updateTransactionAmountToApi,
  deleteTransaction,
} from "../Redux";
import AccountsList from "./AccountsList";
import AlertBanner from "./AlertBanner";
import Spinner from "../Layout/Spinner/Spinner";
import { AppActions, AppState } from "../Redux/Store";
import { ThunkDispatch } from "redux-thunk";
import { Account, TransactionRequest } from "../Types/Interfaces";
import EditTransactionModal from "./Modals/EditTransactionModal";

type Props = {
  isAuthenticated: boolean;
  loadAccounts: () => void;
  isLoading: boolean;
  updateDateToApi: (date: number, id: string) => void;
  isMessage: boolean;
  removeMessage: () => void;
  isErrorMessage: boolean;
  updateAmountToApi: (amount: number, id: string) => void;
  accounts: Account[];
  loadTransactions: () => void;
  deleteTransactionApi: (data: TransactionRequest) => void;
};

function DashBoard({
  isAuthenticated,
  loadAccounts,
  isLoading,
  updateDateToApi,
  isMessage,
  removeMessage,
  isErrorMessage,
  updateAmountToApi,
  accounts,
  loadTransactions,
  deleteTransactionApi,
}: Props) {
  const [editTransactionAmount, setEditTransactionAmount] = useState(0);
  const [editTransactionDate, setEditTransactionDate] = useState(0);
  const [updateID, setUpdateID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      loadAccounts();
      loadTransactions();
    }
    if (!isAuthenticated) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const updateTransactionAmount = (amount: number, id: string) => {
    setEditTransactionAmount(
      (editTransactionAmount) => (editTransactionAmount = amount)
    );
    setUpdateID((updateID) => (updateID = id));
    setShowModal((prevState) => !prevState);
  };

  const updateTransactionDate = (date: number, id: string) => {
    setEditTransactionDate(
      (editTransactionDate) => (editTransactionDate = date)
    );
    setUpdateID((updateID) => (updateID = id));
    setShowModal((prevState) => !prevState);
  };

  const updateDate = (date: number) => {
    updateDateToApi(date, updateID);
  };

  const updateAmount = (amount: number) => {
    updateAmountToApi(amount, updateID);
  };

  const deleteTransactionHandler = (data: TransactionRequest) => {
    deleteTransactionApi(data);
  };

  const removeModal = () => {
    setShowModal((prevState) => !prevState);
    setEditTransactionDate((editTransactionDate) => (editTransactionDate = 0));
    setEditTransactionAmount(
      (editTransactionAmount) => (editTransactionAmount = 0)
    );
  };
  if (isLoading) {
    return <Spinner />;
  }

  if (isMessage || isErrorMessage) {
    setTimeout(() => {
      removeMessage();
    }, 3000);
  }

  return (
    <Fragment>
      <NavBar />
      <div className="w-11/12 mx-auto px-6 relative mb-6 lg:mb-0">
        {isMessage && (
          <div className="mt-10">
            <AlertBanner />
          </div>
        )}
        {showModal ? (
          <EditTransactionModal
            editAmount={editTransactionAmount}
            editDate={editTransactionDate}
            removeModal={removeModal}
            updateDate={updateDate}
            updateAmount={updateAmount}
          />
        ) : (
          ""
        )}
        {accounts ? (
          <div className="lg:grid lg:grid-cols-3 lg:gap-2">
            <div className="lg:col-span-1">
              <AccountsList />
            </div>
            <div className="lg:col-span-2">
              <TransactionList
                updateTransactionAmount={updateTransactionAmount}
                updateTransactionDate={updateTransactionDate}
                deleteTransaction={deleteTransactionHandler}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.authState.isAuthenticated,
    accounts: state.amsState.accounts,
    isLoading: state.amsState.isLoading,
    isLoadingUser: state.authState.isLoading,
    isMessage: state.messageState.hasMessage,
    isErrorMessage: state.messageState.hasError,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    loadAccounts: () => dispatch(fetchAccountsFromAPI()),
    loadTransactions: () => dispatch(fetchTransactionsFromAPI()),
    updateDateToApi: (date: number, id: string) =>
      dispatch(updateTransactionDateToApi(date, id)),
    removeMessage: () => dispatch(clearMessage()),
    updateAmountToApi: (amount: number, id: string) =>
      dispatch(updateTransactionAmountToApi(amount, id)),
    deleteTransactionApi: (data: TransactionRequest) =>
      dispatch(deleteTransaction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
