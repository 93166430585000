import React, { useEffect, useState } from "react";
import FormInputGroup from "../../Layout/FormInputGroup";
import "./modal.css";

interface Props {
  editAmount: number;
  removeModal: () => void;
  editDate: number;
  updateDate: (date: number) => void;
  updateAmount: (amount: number) => void;
}

const EditTransactionModal: React.FC<Props> = ({
  editAmount,
  removeModal,
  editDate,
  updateDate,
  updateAmount,
}) => {
  const [date, setdate] = useState("");
  const [amount, setAmount] = useState("");

  let stringDate = "";
  if (editDate) {
    var inputDate = new Date(editDate * 1000);
    var inputDateString = inputDate.toISOString();
    let inputDateSplit = inputDateString.split("T");
    stringDate = inputDateSplit[0];
  }

  useEffect(() => {
    if (editAmount) {
      setAmount((amount) => (amount = editAmount.toString()));
    } else {
      setdate((date) => (date = stringDate));
    }
    // eslint-disable-next-line
  }, []);

  const onAmountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount((amount) => (amount = e.target.value));
  };

  const onDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setdate((date) => (date = e.target.value));
  };

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (editDate) {
      let transactionDate = new Date(date).getTime();
      transactionDate = transactionDate / 1000;
      updateDate(transactionDate);
    }
    if (editAmount) {
      updateAmount(parseInt(amount));
    }
    removeModal();
  };

  return (
    <div className="absolute inset-0" id="modal">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform 
          transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full animate"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <form onSubmit={formSubmitHandler}>
            <div className="px-4 py-5 bg-white sm:p-6 border-b border-gray-200">
              <div className="grid grid-rows-1">
                {editAmount ? (
                  <FormInputGroup
                    labelText={"Transaction Amount"}
                    inputType={"number"}
                    inputName={"amount"}
                    inputValue={amount}
                    inputChangeHandler={onAmountChangeHandler}
                  />
                ) : (
                  <FormInputGroup
                    labelText={"Transaction Date"}
                    inputType={"date"}
                    inputName={"date"}
                    inputValue={date}
                    inputChangeHandler={onDateChangeHandler}
                  />
                )}
                {/* <FormInputGroup
                  lableText={"Transaction Date"}
                  inputType={"date"}
                  inputName={"date"}
                  inputValue={date}
                  inputChangeHandler={onDateChangeHandler}
                /> */}
              </div>
            </div>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Edit Transaction
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Only Amount or Date Update is allowed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent
                shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary_dark
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary sm:ml-3
                sm:w-auto sm:text-sm"
              >
                Save
              </button>
              <button
                type="button"
                id="cancel"
                className="mt-3 w-full inline-flex justify-center rounded-md border
                border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700
                hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
                focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={removeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTransactionModal;
