import React from "react";

function FormButton() {
  return (
    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm 
        text-sm font-medium rounded-md text-white bg-secondary hover:bg-secondary_dark focus:outline-none 
        focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
      >
        Save
      </button>
    </div>
  );
}

export default FormButton;
