import { AuthState } from "../../Types/Interfaces";
import { AppActions } from "../Store";
import { AUTH_LOADING, LOGIN_SUCCESS, AUTH_ERROR } from "./AuthTypes";

export const intialState: AuthState = {
  token: "",
  isAuthenticated: false,
  isLoading: false,
};

const authReducer = (state = intialState, action: AppActions): AuthState => {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
        isLoading: false,
      };

    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
