import { Account, TransactionRequest } from "../../Types/Interfaces";

export const REQUEST_LOADING = "REQUEST_LOADING";
export const ACCOUNTS_LOADED = "ACCOUNTS_LOADED";
export const TRANSACTIONS_LOADED = "TRANSACTIONS_LOADED";
export const PERSIST_ACCOUNT = "PERSIST_ACCOUNT";
export const PERSIST_TRANSACTION = "PERSIST_TRANSACTION";
export const UPDATE_TRANSACTION_DATE = "UPDATE_TRANSACTION_DATE";
export const UPDATE_TRANSACTION_AMOUNT = "UPDATE_TRANSACTION_AMOUNT";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";

export interface LoadRequestAction {
  type: typeof REQUEST_LOADING;
}

export interface PostTransactionAction {
  type: typeof PERSIST_TRANSACTION;
  transaction: TransactionRequest;
}

export interface FetchTransactionsAction {
  type: typeof TRANSACTIONS_LOADED;
  transactions: TransactionRequest[];
}

export interface UpdateTransactionDateAction {
  type: typeof UPDATE_TRANSACTION_DATE;
  payload: {
    id: string;
    date: number;
  };
}

export interface UpdateTransactionAmountAction {
  type: typeof UPDATE_TRANSACTION_AMOUNT;
  payload: {
    id: string;
    amount: number;
  };
}

export interface DeleteTransaction {
  type: typeof DELETE_TRANSACTION;
  payload: TransactionRequest;
}

export interface PostAccountAction {
  type: typeof PERSIST_ACCOUNT;
  account: Account;
}

export interface FetchAccountsAction {
  type: typeof ACCOUNTS_LOADED;
  accounts: Account[];
}

export type TransactionActionTypes =
  | LoadRequestAction
  | PostTransactionAction
  | FetchTransactionsAction
  | UpdateTransactionAmountAction
  | UpdateTransactionDateAction
  | DeleteTransaction;

export type AccountActionTypes =
  | LoadRequestAction
  | PostAccountAction
  | FetchAccountsAction;
