import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "../Layout/Table";
import TransactionDetail from "./TransactionDetail";
import { Account, TransactionRequest } from "../Types/Interfaces";
import { AppState } from "../Redux/Store";
import SearchComponent from "./SearchComponent";
import {
  listBackgroundColor,
  parseTransactions,
} from "../Utils/HelperFunctions";

interface Props {
  transactions: TransactionRequest[];
  updateTransactionAmount: (amount: number, id: string) => void;
  updateTransactionDate: (date: number, id: string) => void;
  accounts: Account[];
  deleteTransaction: (data: TransactionRequest) => void;
}

function TransactionList({
  transactions,
  updateTransactionAmount,
  updateTransactionDate,
  accounts,
  deleteTransaction,
}: Props) {
  //const itemsPerPage = 5;
  //const pagination = usePagination(transactions, itemsPerPage);

  const [transactionState, setTransactionState] = useState<
    TransactionRequest[]
  >([]);

  const [filteredTransactions, setFilteredTransactions] = useState<
    TransactionRequest[]
  >([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    const updatedData = parseTransactions(transactions, accounts);
    setTransactionState(updatedData);
  }, [transactions, accounts]);

  useEffect(() => {
    setFilteredTransactions(
      transactionState.filter((transaction) => {
        return (
          transaction.debitAccount.toLowerCase().includes(filterText) ||
          transaction.creditAccount.toLowerCase().includes(filterText)
        );
      })
    );
  }, [transactionState, filterText]);

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value.toLowerCase());
  };

  const headers = [
    {
      name: "Date",
    },
    {
      name: "Debit",
    },
    {
      name: "Credit",
    },
    {
      name: "Amount",
    },
    {
      name: "Edit",
    },
    {
      name: "Delete",
    },
  ];

  return (
    <>
      <SearchComponent
        searchText="Recent Transactions"
        searchHandler={searchHandler}
      />

      <div className="align-middle inline-block min-w-full lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <Table headers={headers}>
            {filteredTransactions &&
              filteredTransactions.slice(0, 10).map((transaction, index) => {
                const bgColor = listBackgroundColor(index);
                return (
                  <TransactionDetail
                    key={transaction.id}
                    transaction={transaction}
                    updateTransactionAmount={updateTransactionAmount}
                    updateTransactionDate={updateTransactionDate}
                    deleteTransaction={deleteTransaction}
                    bgColor={bgColor}
                  />
                );
              })}
          </Table>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    transactions: state.amsState.transactions,
    accounts: state.amsState.accounts,
  };
};

export default connect(mapStateToProps)(TransactionList);
