import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import FormHeading from "../Layout/FormHeading";
import { postTransactionToApi, clearMessage } from "../Redux";
import AlertBanner from "./AlertBanner";
import NavBar from "./NavBar";
import FormInputGroup from "../Layout/FormInputGroup";
import FormButton from "../Layout/FormButton";
import { ThunkDispatch } from "redux-thunk";
import { Account, Transaction } from "../Types/Interfaces";
import { AppActions, AppState } from "../Redux/Store";

interface Props {
  isAuthenticated: boolean;
  accounts: Account[];
  postToApi: (data: Transaction) => void;
  removeMessage: () => void;
  isMessage: boolean;
}

const AddTransaction: React.FC<Props> = ({
  isAuthenticated,
  accounts,
  postToApi,
  isMessage,
  removeMessage,
}) => {
  const [transactionDetails, setTransactionDetails] = useState({
    amount: "",
    creditAccount: "default",
    debitAccount: "default",
    description: "",
    date: "",
  });

  const inputChangeHandler = (e: any) => {
    setTransactionDetails({
      ...transactionDetails,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTransactionDetails({
      amount: "",
      creditAccount: "default",
      debitAccount: "default",
      description: "",
      date: "",
    });
    let date = new Date(transactionDetails.date).getTime();
    date = date / 1000;
    const transaction: Transaction = {
      amount: parseInt(transactionDetails.amount),
      date: date,
      debitAccount: transactionDetails.debitAccount,
      creditAccount: transactionDetails.creditAccount,
      description: transactionDetails.description,
    };
    postToApi(transaction);
  };

  if (isMessage) {
    setTimeout(() => {
      removeMessage();
    }, 3000);
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Fragment>
      <NavBar />
      <div className="container mx-auto mt-10 px-6">
        {isMessage ? <AlertBanner /> : ""}
        <FormHeading heading={"Add Transaction"} />
        <form onSubmit={formSubmitHandler}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <FormInputGroup
                labelText={"Transaction Amount"}
                inputType={"number"}
                inputName={"amount"}
                inputValue={transactionDetails.amount}
                inputChangeHandler={inputChangeHandler}
              />
              <FormInputGroup
                labelText={"Transaction Description"}
                inputType={"text"}
                inputName={"description"}
                inputValue={transactionDetails.description}
                inputChangeHandler={inputChangeHandler}
              />
              <div className="col-span-6 sm:col-span-3 mb-6">
                <label
                  htmlFor="debit_account"
                  className="block text-sm font-medium text-gray-700"
                >
                  Debit Account
                </label>
                <select
                  name="debitAccount"
                  value={transactionDetails.debitAccount}
                  onChange={inputChangeHandler}
                  id="debit_account"
                  autoComplete="off"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option disabled value="default">
                    Select Debit Account
                  </option>
                  {accounts.map((account) => (
                    <option
                      value={account.id}
                      className="capitalize"
                      key={account.id}
                    >
                      {account.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3 mb-6">
                <label
                  htmlFor="credit_account"
                  className="block text-sm font-medium text-gray-700"
                >
                  Credit Account
                </label>
                <select
                  name="creditAccount"
                  value={transactionDetails.creditAccount}
                  onChange={inputChangeHandler}
                  id="credit_account"
                  autoComplete="off"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option disabled value="default">
                    Select Credit Account
                  </option>
                  {accounts.map((account) => (
                    <option
                      value={account.id}
                      className="capitalize"
                      key={account.id}
                    >
                      {account.name}
                    </option>
                  ))}
                </select>
              </div>
              <FormInputGroup
                labelText={"Transaction Date"}
                inputType={"date"}
                inputName={"date"}
                inputValue={transactionDetails.date}
                inputChangeHandler={inputChangeHandler}
              />
            </div>
            <FormButton />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.authState.isAuthenticated,
    accounts: state.amsState.accounts,
    isMessage: state.messageState.hasMessage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    postToApi: (data: Transaction) => dispatch(postTransactionToApi(data)),
    removeMessage: () => dispatch(clearMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTransaction);
