import React, { InputHTMLAttributes } from "react";

interface props extends InputHTMLAttributes<HTMLInputElement>{
  labelText: string;
  inputType: string;
  inputName: string;
  inputValue: string;
  inputChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const FormInputGroup: React.FC<props> = ({
  labelText,
  inputType,
  inputName,
  inputValue,
  inputChangeHandler,
  children,
}) => {
  return (
    <div className="col-span-6 sm:col-span-3 mb-6">
      <label
        htmlFor="account_name"
        className="block text-sm font-medium text-gray-700"
      >
        {labelText}
      </label>
      <input
        name={inputName}
        value={inputValue}
        onChange={inputChangeHandler}
        type={inputType}
        autoComplete="off"
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
      {children}
      {/* <span className="error text-sm text-red-600" aria-live="polite">
    Error text Goes Here...
  </span> */}
    </div>
  );
}

export default FormInputGroup;
