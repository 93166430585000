import React from "react";

const NavBarLogo = () => {
  return (
    <div>
      <svg
        width="70"
        height="36"
        viewBox="0 0 92 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M32.3065 61.9006V67.0008H29.7738C22.9938 67.0008 18.4472 66.1267 16.1331 64.3794C13.8455 62.6321 
            12.7017 59.15 12.7017 53.9311V45.4667C12.7017 41.9012 11.9664 39.434 10.4969 38.0649C9.02627 36.6948 6.35775 
            36.0103 2.49132 36.0103H0V30.9456H2.49132C6.38535 30.9456 9.05387 30.2725 10.4969 28.9264C11.9664 27.5573 12.7017 
            25.114 12.7017 21.5955V13.0956C12.7017 7.8767 13.8455 4.40617 16.1331 2.68273C18.4472 0.935259 22.9938 0.0616192 
            29.7738 0.0615234H32.3065V5.12624H29.5287C25.6899 5.12633 23.1848 5.64573 22.0145 6.68472C20.8431 7.72351 20.2579 9.90737 
            20.2579 13.2373V22.0206C20.2579 25.7277 19.6319 28.419 18.3788 30.0954C17.1533 31.7718 15.0434 32.9054 12.0481 33.4952C15.071 
            34.1328 17.1941 35.2903 18.4196 36.9667C19.6451 38.6432 20.2579 41.323 20.2579 45.0061V53.7894C20.2579 57.1193 20.8431 59.3032 22.0145 
            60.342C23.1848 61.3808 25.6899 61.9006 29.5287 61.9006H32.3065"
            fill="#FF6584"
          />
          <path
            d="M59.6928 61.8384V66.9386H62.2244C69.0045 66.9386 73.5521 66.0654 75.8662 64.3181C78.1538 62.5708 79.2976 59.0878 79.2965 
            53.8699V45.4054C79.2976 41.84 80.0318 39.3727 81.5024 38.0027C82.9731 36.6336 85.6416 35.949 89.508 35.949H91.9988V30.8843H89.508C85.614 
            30.8843 82.9455 30.2113 81.5024 28.8651C80.0318 27.496 79.2976 25.0518 79.2965 21.5342V13.0334C79.2976 7.81546 78.1538 4.34455 75.8662 
            2.62053C73.5521 0.873735 69.0045 9.57413e-05 62.2244 0H59.6928V5.06471H62.4695C66.3094 5.06471 68.8146 5.58421 69.9849 6.62348C71.1563 
            7.66227 71.7414 9.84613 71.7414 13.1751V21.9584C71.7414 25.6655 72.3674 28.3577 73.6194 30.0341C74.8449 31.7106 76.9559 32.8441 79.9501 
            33.4339C76.9283 34.0715 74.8041 35.2281 73.5797 36.9045C72.3542 38.581 71.7414 41.2617 71.7414 44.9449V53.7282C71.7414 57.0571 71.1563 
            59.2419 69.9849 60.2807C68.8146 61.3195 66.3094 61.8384 62.4695 61.8384H59.6928"
            fill="#FF6584"
          />
          <path
            d="M36.6016 45V19.4062H44.1602C46.4219 19.4062 48.4258 19.9102 50.1719 20.918C51.9297 21.9258 53.2891 23.3555 54.25 25.207C55.2109 
            27.0586 55.6914 29.1797 55.6914 31.5703V32.8535C55.6914 35.2793 55.2051 37.4121 54.2324 39.252C53.2715 41.0918 51.8945 42.5098 50.1016 
            43.5059C48.3203 44.502 46.2754 45 43.9668 45H36.6016ZM41.0488 22.9922V41.4492H43.9492C46.2812 41.4492 48.0684 40.7227 49.3105 
            39.2695C50.5645 37.8047 51.2031 35.707 51.2266 32.9766V31.5527C51.2266 28.7754 50.623 26.6543 49.416 25.1895C48.209 23.7246 46.457 
            22.9922 44.1602 22.9922H41.0488Z"
            fill="#F4F4F4"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="92" height="67" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default NavBarLogo;
