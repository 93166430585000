import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import NavBar from "./NavBar";
import { addAccountToApi, clearMessage } from "../Redux";
import FormHeading from "../Layout/FormHeading";
import FormInputGroup from "../Layout/FormInputGroup";
import FormButton from "../Layout/FormButton";
import AlertBanner from "./AlertBanner";
import { AppActions, AppState } from "../Redux/Store";
import { ThunkDispatch } from "redux-thunk";

interface props {
  isAuthenticated: boolean;
  addAccount: (data: string) => void;
  isLoading: boolean;
  removeMessage: () => void;
  isMessage: boolean;
}

const AddAccount: React.FC<props> = ({
  isAuthenticated,
  addAccount,
  isLoading,
  removeMessage,
  isMessage,
}) => {
  const [accountName, setAccountName] = useState<string>("");

  const formChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(e.target.value);
  };

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addAccount(accountName);

    setAccountName("");
  };

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <div>Loading....</div>;
  }

  if (isMessage) {
    setTimeout(() => {
      removeMessage();
    }, 3000);
  }
  //console.log("this rendered");
  return (
    <Fragment>
      <NavBar />
      <div className="container mx-auto lg:max-w-6xl mt-10 md:max-w-lg px-6">
        {isMessage ? <AlertBanner /> : ""}
        <FormHeading heading={"Add Account"} />
        <form onSubmit={formSubmitHandler}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <FormInputGroup
                labelText={"Account Name"}
                inputType={"text"}
                inputName={"accountName"}
                inputValue={accountName}
                inputChangeHandler={formChangeHandler}
              />
            </div>
            <FormButton />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: state.authState.isAuthenticated,
    isLoading: state.amsState.isLoading,
    isMessage: state.messageState.hasMessage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    addAccount: (payload: string) => dispatch(addAccountToApi(payload)),
    removeMessage: () => dispatch(clearMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
