export const AUTH_LOADING = "AUTH_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export interface LoadAuthAction {
  type: typeof AUTH_LOADING;
}

export interface SetErrorAction {
  type: typeof AUTH_ERROR;
}

export interface SetLoginAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
}

export type AuthActionTypes = LoadAuthAction | SetErrorAction | SetLoginAction;
